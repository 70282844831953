/* ==========================================================================
   Typography
   ========================================================================== */

.barlow-condensed {
  font-family: 'Barlow Condensed', sans-serif;;
}
.base-font {
  font-family: 'Montserrat', sans-serif;
}
