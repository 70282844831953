/* Color palette */
$red: #d73f3f;
$red-light: #ffeded;
$orange: #faa71e;
$blue-dark: #2c3038;
$blue-light: #929db3;
$blue-grey: #68707f;
$grey-light: #e1e0e0;
$tan: #f0efef;
$white: #fff;
$cyan: #008384;
