.org-usaid {
  background: no-repeat center url('../img/organizations/usaid.png');
}
.org-redcross {
  background: no-repeat center url('../img/organizations/redcross.png');
}
.org-msf {
  background: no-repeat center url('../img/organizations/msf.png');
}
.org-brc {
  background: no-repeat center url('../img/organizations/brc.png');
}
.org-wb {
  background: no-repeat center url('../img/organizations/wb.png');
}
.org-bing {
  background: no-repeat center url('../img/organizations/bing.png');
}
.org-itmission {
  background: no-repeat center url('../img/organizations/itmission.png');
}
.org-ksdi {
  background: no-repeat center url('../img/organizations/ksdi.png');
}
.org-icfoss {
  background: no-repeat center url('../img/organizations/icfoss.png');
}
.org-nss {
  background: no-repeat center url('../img/organizations/nss.png');
}
.org-rki {
    background: no-repeat center url('../img/organizations/rki.svg');
}
.org-cdit {
    background: no-repeat center url('../img/organizations/c-dit.svg');
}
.org-hkm {
    background: no-repeat center url('../img/organizations/harithaKeralaMission.png');
}
